import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";

import { User } from "../_models/user";
import { map } from "rxjs/operators";
import { Status } from "../_models/status";
import { ThrowStmt } from "@angular/compiler";
import { List } from "../_models/list";

@Injectable({ providedIn: "root" })
export class AutomationService {
  constructor(private http: HttpClient) {}

  public baseUrl: String =
    "https://svatantramicrofin-staging.quickwork.co/onboarding/api/v1";
  //public baseUrl: String = "http://localhost:8080/onboarding/api/v1";
  // public baseUrl: String =
  //   "http://svatantramicrofinhrstagingbackv1.quickwork.co:8080/onboarding/api/v1";

  httpOptions = {
    headers: new HttpHeaders({
      apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
    }),
  };

  getAllStatus(moderatorId: string) {
    return this.http
      .get<Status>(`${this.baseUrl}/status/fetch_statuses`, {
        params: { moderatorId: moderatorId },
      })
      .pipe((status) => {
        return status;
      });
  }

  getListForSpecificStatus(status: string, moderatorId: string) {
    return this.http.get<List[]>(`${this.baseUrl}/list/data`, {
      params: { status: status, moderatorId: moderatorId },
    });
  }

  fetchSurveyFieldForm(employeeId) {
    return this.http.get<any>(`${this.baseUrl}/form/fetch_refill_field_form`, {
      params: { employeeId: employeeId },
    });
  }

  fetchSurveyInitialEmployeeDetailsFieldForm(employeeId) {
    return this.http.get<any>(
      `${this.baseUrl}/form/fetch_initial_employee_details`,
      { params: { employeeId: employeeId } }
    );
  }

  fetchCurrentStatusObjectOfCandidate(employeeId) {
    return this.http.get<any>(`${this.baseUrl}/form/fetch_employee_details`, {
      params: { employeeId: employeeId },
    });
  }

  ocr_scanning(type, imageUrl) {
    return this.http.post<any>(`${this.baseUrl}/form/ocr_scanning`, null, {
      params: { type: type, imageUrl: imageUrl },
    });
  }

  cropPhoto(imageUrl) {
    return this.http.post<any>(
      "https://apim.quickwork.co/ayyub/svatantra/v1/cropimage",
      { imageUrl },
      this.httpOptions
    );
  }

  submitFieldForm(data, employeeId) {
    return this.http.post<any>(`${this.baseUrl}/form/submit_field_form`, data, {
      params: { employeeId: employeeId },
    });
  }

  submitDocumentForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/submit_document_form`,
      data,
      { params: { employeeId: employeeId } }
    );
  }

  uploadFile(data) {
    return this.http.post<any>(`${this.baseUrl}/form/upload_files`, data, {});
  }

  fetchFieldChecklist(employeeId: string) {
    return this.http.get<any>(
      `${this.baseUrl}/checklist/fetch_field_checklist`,
      { params: { employeeId: employeeId } }
    );
  }

  fetchDocumentChecklist(employeeId: string) {
    return this.http.get<any>(
      `${this.baseUrl}/checklist/fetch_document_checklist`,
      { params: { employeeId: employeeId } }
    );
  }

  submitFieldChecklist(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/checklist/submit_field_checklist`,
      data,
      { params: { employeeId: employeeId } }
    );
  }

  submitDocumentChecklist(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/checklist/submit_document_checklist`,
      data,
      { params: { employeeId: employeeId } }
    );
  }

  saveFieldForm(data, employeeId) {
    return this.http.post<any>(`${this.baseUrl}/form/save_field_form`, data, {
      params: { employeeId: employeeId },
    });
  }

  saveFieldResubmitForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/save_field_refilled_form`,
      data,
      { params: { employeeId: employeeId } }
    );
  }

  saveDocumentForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/save_document_form`,
      data,
      { params: { employeeId: employeeId } }
    );
  }

  saveDocumentReSubmitForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/save_document_refilled_form`,
      data,
      { params: { employeeId: employeeId } }
    );
  }

  fetchSavedSurveyFieldForm(employeeId) {
    return this.http.get<any>(
      `${this.baseUrl}/form/fetchSavedSurveyFieldForm`,
      { params: { employeeId: employeeId } }
    );
  }

  fetchSavedSurveyDocumentForm(employeeId) {
    return this.http.get<any>(
      `${this.baseUrl}/form/fetchSavedSurveyDocumentForm`,
      { params: { employeeId: employeeId } }
    );
  }

  uploadFileWithProgress(data) {
    console.log(data);
    data.name = data.name.replace(/ {1,}/g, "").trim();
    return this.http
      .post<any>(`${this.baseUrl}/form/upload_files`, data, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event) => {
          console.log("event: " + event);

          return event;
          // switch (event.type) {

          //   case HttpEventType.UploadProgress:
          //     const progress = Math.round(100 * event.loaded / event.total);
          //     console.log("PROGRESS: "+ progress);
          //     return { status: 'progress', message: progress };

          //   case HttpEventType.Response:
          //   console.log("Completed: ");
          //     return event.body;
          //   default:
          //     return `Unhandled event: ${event.type}`;
          // }
        })
      );
  }

  uploadFileAsync(data) {
    console.log("inhere");
    console.log(data);

    return this.http
      .post<any>(`${this.baseUrl}/form/upload_files_async`, data, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event) => {
          console.log("event: " + event);

          return event;
          // switch (event.type) {

          //   case HttpEventType.UploadProgress:
          //     const progress = Math.round(100 * event.loaded / event.total);
          //     console.log("PROGRESS: "+ progress);
          //     return { status: 'progress', message: progress };

          //   case HttpEventType.Response:
          //   console.log("Completed: ");
          //     return event.body;
          //   default:
          //     return `Unhandled event: ${event.type}`;
          // }
        })
      );
  }

  uploadFileAsyncAwait(data) {
    console.log("inhere");
    console.log(data);

    return this.http
      .post<any>(`${this.baseUrl}/form/upload_files_async`, data)
      .toPromise();
  }

  getAllModerators() {
    return this.http
      .get<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchmoderators",
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
  getAllCandidatesforModerator(moderatorId: string) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchcandidates",
        { moderatorId: moderatorId },
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
  transferModerator(payload) {
    return this.http.post<any>(
      "https://automation.quickwork.co/staticwebhook/api/http_app/notify/5d67c511ab254016bbf0729e/transfer-candidate",
      payload
    );
  }
  getAllStates() {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchstates",
        {},
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
  getAllBranchesByState(stateName) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchbranchesbystate",
        { state: stateName },
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
}
