import { Component, OnInit, AfterContentInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ModalService } from "../_services/modal.service";
import { User } from "../_models/user";
import { AuthenticationService } from "../_services/authentication.service";
import { UserService } from "../_services/user.service";
import { AutomationService } from "../_services/automation.service";
import { first } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import * as Survey from "survey-angular";
import { DomSanitizer } from "@angular/platform-browser";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: "app-checklist",
  templateUrl: "./checklist.component.html",
  styleUrls: ["./checklist.component.css"],
})
export class ChecklistComponent implements OnInit {
  currentUser: User;
  bodyText: string = "Hi pankaj";
  index: string;
  currentUserSubscription: Subscription;
  showChecklist: boolean = true;
  stage: number;
  employeeId: string;
  showChecklistLoader: boolean = false;
  jsondata: any;
  transform: ImageTransform = {};
  scale = 1;
  canvasRotation = 0;

  ordersData = [];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private modalService: ModalService,
    private automationService: AutomationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public domSanitizer: DomSanitizer
  ) {
    this.stage = this.activatedRoute.snapshot.queryParams["stage"];
    try {
      this.employeeId = this.activatedRoute.snapshot.queryParams["employeeId"];
    } catch (error) {
      this.employeeId = this.currentUser.selectedUserId;
    }

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      (user) => {
        this.currentUser = user;
        console.log(this.currentUser);
      }
    );
  }

  passportImageUrl: any = "";
  imageChangedEvent: any = "";
  croppedImage: any = "";

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log("CROPPED ", event);
  }

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  ngOnInit() {
    if (this.stage == 1) {
      this.showChecklistLoader = true;
      this.automationService
        .fetchFieldChecklist(this.employeeId)
        .pipe(first())
        .subscribe(
          (data) => {
            this.jsondata = {};
            this.ordersData = data;
            console.log(this.ordersData);
            this.ordersData.forEach((order) => {
              if (order.key == "DateOfMarriage" || order.key == "DateOfBirth") {
                console.log("Hey Hey");
                var val = "";
                if (order.value.indexOf("-") !== -1) {
                  console.log("VALID");
                  val = order.value;
                } else {
                  console.log("INVALID");
                  var time = new Date(parseInt(order.value));
                  var theyear = time.getFullYear();
                  var themonth = time.getMonth() + 1;
                  var themonthstring = themonth > 9 ? themonth : "0" + themonth;
                  var thetoday =
                    time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
                  var dateString =
                    theyear + "-" + themonthstring + "-" + thetoday;
                  console.log(dateString);
                  val = dateString;
                }
                order.value = val;
              }
              if (
                order.key == "familyDetails" ||
                order.key == "educationalDetails" ||
                order.key == "professionalDetails" ||
                order.key == "nomineeDetails" ||
                order.key == "referenceDetails"
              ) {
                console.log("Hey Matrix Dynamic");
                console.log(order.value);
                var val = "";
                var b = order.value.replace(/&quot;/g, '"');
                val = JSON.parse(b);
                order.value = val;
                order.type = "matrixdynamic";
                this.jsondata[order.key] = order.value;
              }
              // if (order.key == "signature") {
              //   console.log("Hey Signature");
              //   console.log(order.value);
              //   // var val = "";
              //   // var b = order.value.replace(/&quot;/g, '"');
              //   // val = JSON.parse(b);
              //   // order.value = val;
              //   order.type = "signature";
              //   order.value = order.value.replace(/&#x3D;/g, "=");
              //   // console.log(order.value);
              //   // co
              //   // order.value =
              //   // this.jsondata[order.key] = order.value;
              // }
            });
            this.showChecklistLoader = false;
            console.log("submission result : ", data);
          },
          (error) => {
            this.showChecklistLoader = false;
            console.log("Error occured!!!");
          }
        );
    } else {
      this.showChecklistLoader = true;
      this.automationService
        .fetchDocumentChecklist(this.employeeId)
        .pipe(first())
        .subscribe(
          (data) => {
            for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
              const element = data[dataIndex];
              if (
                element.key != "question1" &&
                element.key != "question2" &&
                element.key != "question3" &&
                element.key != "question4" &&
                element.key != "question5"
              ) {
                this.ordersData.push(element);
              }
            }
            // this.ordersData = data;
            this.showChecklistLoader = false;
            console.log("submission result : ", data);
          },
          (error) => {
            this.showChecklistLoader = false;
            console.log("Error occured!!!");
          }
        );
    }
  }

  openModal(id: string) {
    console.log("URL : ", id);
    this.index = id;
    this.modalService.open("custom-modal-1");
  }

  closeModal(id: string) {
    this.modalService.close("custom-modal-1");
  }

  async uploadPhoto(payload) {
    return await this.automationService.uploadFileAsyncAwait(payload);
  }

  async submit(input) {
    console.log(input);
    this.showChecklist = false;

    if (this.stage == 1) {
      var removeName = undefined;
      for (let counter = 0; counter < input.length; counter++) {
        const element = input[counter];

        console.log(element);
        if (element.key == "DateOfMarriage") {
          console.log("DateOfMarriage");
          console.log(element.value);
          element.value = new Date(element.value).getTime();
          console.log(element.value);
        }

        if (
          element.key == "UploadPassportSizePhoto" &&
          element.checked == true
        ) {
          console.log("PASSPORT SIZE PHOTO ", element);
          var payload = {
            name: "/SvatantraHR/" + new Date().getTime() + ".jpeg",
            type: "image/jpeg",
            content: this.croppedImage.split(",")[1],
          };

          await this.uploadPhoto(payload).then((data) => {
            console.log("FETCHED URL ", JSON.parse(data.body).Location);
            element.value = JSON.parse(data.body).Location;
          });
        }

        if (element.key == "DateOfBirth") {
          console.log("DateOfBirth");
          console.log(element.value);
          element.value = new Date(element.value).getTime();
          console.log(element.value);
        }

        if (element.key == "NameAsPerAadhar") {
          removeName = counter;
        }

        if (element.type == "matrixdynamic") {
          try {
            element.value = JSON.stringify(
              element.value.replace(/&quot;/g, '"')
            );
          } catch (ex) {
            element.value = JSON.stringify(element.value);
          }
        }
        // if (element.type == "signature") {
        //   try {
        //     element.value = element.value.replace(/&#x3D;/g, "=");
        //   } catch (ex) {
        //     element.value = element.value;
        //   }
        // }
      }

      if (removeName != undefined) {
        input.splice(removeName, 1);
      }
      console.log("before submit call");
      console.log(input);

      this.automationService
        .submitFieldChecklist({ records: input }, this.employeeId)
        .pipe(first())
        .subscribe(
          (data) => {
            this.ordersData = data;
            console.log("submission result : ", data);
            setTimeout(() => this.router.navigate(["/"]), 400);
          },
          (error) => {
            console.log("Error occured!!!");
          }
        );
    } else {
      this.automationService
        .submitDocumentChecklist({ records: input }, this.employeeId)
        .pipe(first())
        .subscribe(
          (data) => {
            this.ordersData = data;
            console.log("submission result : ", data);
            setTimeout(() => this.router.navigate(["/"]), 400);
          },
          (error) => {
            console.log("Error occured!!!");
          }
        );
    }
  }

  loopAndUpdateCheckbox(key, bool) {
    this.ordersData.forEach((order) => {
      if (order.key == key) {
        order.checked = bool;
      }
    });
  }

  dataChanged(i, key) {
    console.log(i + key);
    console.log(this.ordersData[i]);

    if (this.ordersData[i].key == "BankAccountNo") {
      if (this.ordersData[i + 1].key == "ConfirmBankAccountNo") {
        this.ordersData[i + 1].checked = this.ordersData[i].checked;
      } else {
        this.loopAndUpdateCheckbox(
          "ConfirmBankAccountNo",
          this.ordersData[i].checked
        );
      }
    }
    if (this.ordersData[i].key == "ConfirmBankAccountNo") {
      if (this.ordersData[i - 1].key == "BankAccountNo") {
        this.ordersData[i - 1].checked = this.ordersData[i].checked;
      } else {
        this.loopAndUpdateCheckbox("BankAccountNo", this.ordersData[i].checked);
      }
    }
  }
}
