import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";

import { User } from "../_models/user";
import { map } from "rxjs/operators";
import { Status } from "../_models/status";
import { ThrowStmt } from "@angular/compiler";
import { List } from "../_models/list";

@Injectable({ providedIn: "root" })
export class AutomationService {
  constructor(private http: HttpClient) {}

  //OLD JAVA GATEWAY URL

  //public baseUrl: String =  "https://svatantramicrofin-staging.quickwork.co/onboarding/api/v1";

  public baseUrl: String =
    "https://apim.quickwork.co/ayyub/onboarding/migrated/v1";

  //public baseUrl: String = "http://localhost:8080/onboarding/api/v1";
  // public baseUrl: String =
  //   "http://svatantramicrofinhrstagingbackv1.quickwork.co:8080/onboarding/api/v1";

  httpOptions = {
    headers: new HttpHeaders({
      apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
    }),
  };

  getAllStatus(moderatorId: string) {
    return this.http
      .get<Status>(`${this.baseUrl}/status/fetch_statuses`, {
        params: { moderatorId: moderatorId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      })
      .pipe((status) => {
        return status;
      });
  }

  getListForSpecificStatus(status: string, moderatorId: string) {
    return this.http.get<List[]>(`${this.baseUrl}/list/data`, {
      params: { status: status, moderatorId: moderatorId },
      headers: new HttpHeaders({
        apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
      }),
    });
  }

  fetchSurveyFieldForm(employeeId) {
    return this.http.get<any>(`${this.baseUrl}/form/fetch_refill_field_form`, {
      params: { employeeId: employeeId },
      headers: new HttpHeaders({
        apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
      }),
    });
  }

  fetchSurveyInitialEmployeeDetailsFieldForm(employeeId) {
    return this.http.get<any>(
      `${this.baseUrl}/form/fetch_initial_employee_details`,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  fetchCurrentStatusObjectOfCandidate(employeeId) {
    return this.http.get<any>(`${this.baseUrl}/form/fetch_employee_details`, {
      params: { employeeId: employeeId },
      headers: new HttpHeaders({
        apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
      }),
    });
  }

  ocr_scanning(type, imageUrl) {
    return this.http.post<any>(`${this.baseUrl}/form/ocr_scanning`, null, {
      params: { type: type, imageUrl: imageUrl },
      headers: new HttpHeaders({
        apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
      }),
    });
  }

  cropPhoto(imageUrl) {
    return this.http.post<any>(
      "https://apim.quickwork.co/ayyub/svatantra/v1/cropimage",
      { imageUrl },
      this.httpOptions
    );
  }

  submitFieldForm(data, employeeId) {
    return this.http.post<any>(`${this.baseUrl}/form/submit_field_form`, data, {
      params: { employeeId: employeeId },
      headers: new HttpHeaders({
        apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
      }),
    });
  }

  submitDocumentForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/submit_document_form`,
      data,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  uploadFile(data) {
    return this.http.post<any>(`${this.baseUrl}/form/upload_files`, data, {});
  }

  fetchFieldChecklist(employeeId: string) {
    return this.http.get<any>(
      `${this.baseUrl}/checklist/fetch_field_checklist`,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  fetchDocumentChecklist(employeeId: string) {
    return this.http.get<any>(
      `${this.baseUrl}/checklist/fetch_document_checklist`,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  submitFieldChecklist(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/checklist/submit_field_checklist`,
      data,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  submitDocumentChecklist(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/checklist/submit_document_checklist`,
      data,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  saveFieldForm(data, employeeId) {
    console.log("saveFieldForm ", data);

    return this.http.post<any>(
      `${this.baseUrl}/form/save_field_form`,
      { records: data },
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
          "Content-Type": "application/json",
        }),
      }
    );
  }

  saveFieldResubmitForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/save_field_refilled_form`,
      data,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  saveDocumentForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/save_document_form`,
      data,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  saveDocumentReSubmitForm(data, employeeId) {
    return this.http.post<any>(
      `${this.baseUrl}/form/save_document_refilled_form`,
      data,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  fetchSavedSurveyFieldForm(employeeId) {
    return this.http.get<any>(
      `${this.baseUrl}/form/fetchSavedSurveyFieldForm`,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  fetchSavedSurveyDocumentForm(employeeId) {
    return this.http.get<any>(
      `${this.baseUrl}/form/fetchSavedSurveyDocumentForm`,
      {
        params: { employeeId: employeeId },
        headers: new HttpHeaders({
          apiKey: "02K5tPFkg1CUODVBTAUIJKEtD3LUBLsS",
        }),
      }
    );
  }

  uploadFileWithProgress(data) {
    console.log(data);
    data.name = data.name.replace(/ {1,}/g, "").trim();

    var mediaType = data.type;

    var fileName = "SvatantraHR/" + new Date().getTime() + "_" + data.name;

    if (mediaType == "application/pdf") {
      fileName = fileName + ".pdf";
    }
    if (mediaType == "image/jpeg" || mediaType == "image/jpg") {
      fileName = fileName + ".jpg";
    }
    if (mediaType == "image/png") {
      fileName = fileName + ".png";
    }

    var jsonInput = {};

    jsonInput["username"] = fileName;
    jsonInput["contentType"] = mediaType;
    jsonInput["avatar_image"] = data.content.split(",")[1];

    return (
      this.http

        //.post<any>(`${this.baseUrl}/form/upload_files`, data, {
        .post<any>(
          `https://jdrpqq3ade.execute-api.ap-south-1.amazonaws.com/Prod/upload-image`,
          jsonInput,
          {
            reportProgress: true,
            observe: "events",
          }
        )
        .pipe(
          map((event) => {
            console.log("event: " + event);

            return event;
          })
        )
    );
  }

  uploadFileAsync(data) {
    console.log("inhere");
    console.log(data);

    // var settings = {
    //   async: true,
    //   crossDomain: true,
    //   url:
    //     "https://jdrpqq3ade.execute-api.ap-south-1.amazonaws.com/Prod/upload-image",
    //   method: "POST",
    //   processData: false,
    //   data: JSON.stringify(jsonInput),
    // };
    // $.ajax(settings).done(function (response) {
    //   photoContent = JSON.parse(response.body).Location;

    //   console.log(photoContent);
    // });

    return this.http
      .post<any>(
        `https://jdrpqq3ade.execute-api.ap-south-1.amazonaws.com/Prod/upload-image`,
        data,
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        map((event) => {
          console.log("event: " + event);

          return event;
        })
      );

    // return this.http
    //   .post<any>(
    //     `https://svatantramicrofin-staging.quickwork.co/onboarding/api/v1/form/upload_files_async`,
    //     data,
    //     {
    //       reportProgress: true,
    //       observe: "events",
    //     }
    //   )
    //   .pipe(
    //     map((event) => {
    //       console.log("event: " + event);

    //       return event;
    //     })
    //   );
  }

  uploadFileAsyncAwait(data) {
    console.log("inhere");
    console.log(data);

    console.log(data);
    data.name = data.name.replace(/ {1,}/g, "").trim();

    var mediaType = data.type;

    //var fileName = data.name;

    var fileName = "SvatantraHR/" + new Date().getTime() + "_" + data.name;

    if (mediaType == "application/pdf") {
      fileName = fileName + ".pdf";
    }
    if (mediaType == "image/jpeg" || mediaType == "image/jpg") {
      fileName = fileName + ".jpg";
    }
    if (mediaType == "image/png") {
      fileName = fileName + ".png";
    }

    var jsonInput = {};

    jsonInput["username"] = fileName;
    jsonInput["contentType"] = mediaType;
    jsonInput["avatar_image"] = data.content;

    return this.http
      .post<any>(
        `https://jdrpqq3ade.execute-api.ap-south-1.amazonaws.com/Prod/upload-image`,
        jsonInput
      )
      .toPromise();
  }

  getAllModerators() {
    return this.http
      .get<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchmoderators",
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
  getAllCandidatesforModerator(moderatorId: string) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchcandidates",
        { moderatorId: moderatorId },
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
  transferModerator(payload) {
    return this.http.post<any>(
      "https://automation.quickwork.co/staticwebhook/api/http_app/notify/5d67c511ab254016bbf0729e/transfer-candidate",
      payload
    );
  }
  getAllStates() {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchstates",
        {},
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
  getAllBranchesByState(stateName) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/fetchbranchesbystate",
        { state: stateName },
        this.httpOptions
      )
      .pipe((response) => {
        console.log(response);
        return response;
      });
  }
}
